<template>
    <div class="flex justify-center items-center bg-black py-12 px-8">
      <div class="w-full bg-black shadow-lg rounded-lg text-white">
        <div class="flex flex-col lg:flex-row">
          <!-- Section de texte à gauche -->
          <div class="w-full lg:w-1/2 pr-8 mb-8 lg:mb-0">
            <h1 class="text-2xl font-semibold mb-4">Questions Fréquemment Posées</h1>
            <p class="text-gray-300 mb-8">
              Découvrez les réponses aux questions les plus courantes concernant DataFuse et nos services. Si vous souhaitez en savoir plus ou discuter d'un projet, n'hésitez pas à prendre rendez-vous avec nous.
            </p>
            <a href="#" class="text-white underline">Contacter le support</a><br>
            <a href="#" class="text-white underline mt-4 block">Prendre rendez-vous pour discuter de votre projet</a>
          </div>
  
          <!-- Section des questions et réponses -->
          <div class="w-full lg:w-1/2">
            <div v-for="(faq, index) in faqs" :key="index" class="mb-4">
              <div class="bg-gray-800 p-4 rounded-lg shadow-md">
                <div class="flex justify-between items-center">
                  <h2 class="text-lg font-semibold">{{ faq.question }}</h2>
                  <span 
                    class="text-xl font-semibold cursor-pointer" 
                    @click="toggleAnswer(index)">
                    {{ faq.isOpen ? '×' : '+' }}
                  </span>
                </div>
                <p v-if="faq.isOpen" class="text-gray-300 mt-2">{{ faq.answer }}</p>
              </div>
              <hr class="border-t border-gray-300 mb-4" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'FaqSection',
    data() {
      return {
        faqs: [
          {
            question: 'Quels services propose DataFuse ?',
            answer: 'DataFuse offre des services de développement web, marketing digital, et des solutions d\'automatisation, avec un accent particulier sur l\'optimisation des processus et l\'intégration de l\'IA.',
            isOpen: false
          },
          {
            question: 'Comment DataFuse peut-il aider mon entreprise ?',
            answer: 'Nous nous spécialisons dans la création de solutions sur mesure qui améliorent l\'efficacité opérationnelle, automatisent les tâches répétitives, et améliorent l\'expérience utilisateur, permettant ainsi à votre entreprise de croître efficacement.',
            isOpen: false
          },
          {
            question: 'Quel est le processus pour mettre en place l\'automatisation avec DataFuse ?',
            answer: 'Nous analysons d\'abord les processus de votre entreprise, identifions les tâches pouvant être automatisées, puis nous mettons en place des solutions basées sur l\'IA qui simplifient ces tâches, économisant ainsi du temps et réduisant les erreurs humaines.',
            isOpen: false
          },
          {
            question: 'DataFuse peut-il développer des sites web personnalisés pour mon entreprise ?',
            answer: 'Oui, nous proposons des services de développement web sur mesure, créant des sites responsives, optimisés pour le SEO, qui répondent aux objectifs de votre entreprise et offrent une expérience utilisateur fluide.',
            isOpen: false
          },
          {
            question: 'Comment DataFuse intègre-t-il l\'IA dans ses solutions ?',
            answer: 'DataFuse intègre des technologies d\'IA telles que des chatbots, l\'analyse prédictive, et la personnalisation du contenu pour améliorer les interactions avec les clients et automatiser les processus de prise de décision.',
            isOpen: false
          }
        ]
      };
    },
    methods: {
      toggleAnswer(index) {
        this.faqs[index].isOpen = !this.faqs[index].isOpen;
      }
    }
  };
  </script>
  
  <style scoped>
  /* Styles spécifiques pour le composant */
  .bg-black {
    background-color: #000;
  }
  
  .text-white {
    color: #fff;
  }
  
  .text-gray-300 {
    color: #d1d5db;
  }
  
  .shadow-lg {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  }
  
  .bg-gray-800 {
    background-color: #2d2d2d;
  }
  
  .shadow-md {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  </style>
  