<template>
  <div class="p-8">
    <div class="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
      <div class="text-center">
        <p class="text-white">200+ Avis 5 étoiles</p>
        <h2 class="text-3xl font-extrabold text-white sm:text-4xl">
          Ce que nos clients disent de nous
        </h2>
      </div>
      <div class="mt-12 grid gap-8 lg:grid-cols-3 glow">
        <div
          class=" bg-white p-6 rounded-lg shadow-lg"
          v-for="(testimonial, index) in testimonials"
          :key="index"
        >
          <div class="flex items-center mb-4">
            <div class="flex items-center">
              <i v-for="star in 5" :key="star" class="fas fa-star text-yellow-500"></i>
            </div>
          </div>
          <p class="text-gray-700 mb-6">{{ testimonial.quote }}</p>
          <div class="flex items-center">
            <img
              :src="testimonial.image"
              alt="Portrait"
              class="w-10 h-10 rounded-full mr-4"
              height="100"
              width="100"
            />
            <div>
              <p class="font-semibold text-gray-900">{{ testimonial.name }}</p>
              <p class="text-gray-500">{{ testimonial.position }}</p>
            </div>
          </div>
        </div>
      </div>
      
    </div>
    <div class="text-center mb-12">
      <h2 class="text-[#d862b4] text-lg">Nos réalisations</h2>
      <h1 class="text-white text-4xl font-bold">Nos derniers projets</h1>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      <div v-for="(project, index) in projects" :key="index" class="project-card">
        <img :alt="'Image of ' + project.title" :src="project.image" class="w-full h-auto"/>
        <div class="content">
          <h3 class=" text-[#d862b4] title">{{ project.title }}</h3>
          <p class="text-white description">{{ project.description }}</p>
          <button class="button" @click="openProject(project.link)">Open <i class="fas fa-arrow-right"></i></button>
        </div>
      </div>
    </div>

    <!-- Modal pour afficher l'iframe -->
    <div v-if="iframeVisible" class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div class="relative bg-white w-full max-w-4xl p-4 rounded-lg shadow-lg">
        <button class="absolute top-0 right-0 mt-2 mr-2 text-2xl text-gray-600" @click="closeModal">×</button>
        <iframe :src="iframeUrl" width="100%" height="500px" class="border-0 rounded-lg"></iframe>
        <div class="text-center mt-4">
          <a :href="iframeUrl" target="_blank" class="button">Visiter le site</a>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  data() {
    return {
      testimonials: [
  {
    name: 'Ines',
    position: 'Pâtissière',
    quote: '“J\'ai fait appel à DATAFUSE pour la refonte de mon site internet, et je suis plus que satisfaite du résultat. Le design est moderne, intuitif, et parfaitement adapté à nos besoins. Ils ont pris soin d\'intégrer des fonctionnalités avancées comme un système de réservation,”',
    image: 'https://lh3.googleusercontent.com/a-/ALV-UjXTkrfnvXFrdMssqA2Lo96-43tm5sJKlL68LSZr5iz_heKjnuI-RA=s72-c-rp-mo-br100',
  },
  {
    name: 'Naturel&Joli',
    position: 'Esthéticienne',
    quote: '“Très satisfaite du service de DATAFUSE. Le site internet dynamique est superbe et fonctionne parfaitement. Gestion facile du contenu et des rendez-vous en ligne. Service client exceptionnel. Recommandé !”',
    image: 'https://lh3.googleusercontent.com/a/ACg8ocIQ5YdZxTrl7iNbqs08uU4EhXUIdEWHlc1NP_L-z4ll8Qra2g=s72-c-rp-mo-br100',
  },
  {
    name: 'Enzo',
    position: 'Rénovation Intérieure',
    quote: '“DataFuse a créé notre site de rénovation avec professionnalisme et précision. Nous sommes ravis du résultat. Recommandé !”',
    image: 'https://lh3.googleusercontent.com/a/ACg8ocL2chc-KuPWpeA898twLJQGgmn7ttChne7fIhTKsPj-2gaNLg=s72-c-rp-mo-br100',
  },
],

      // Liste des projets avec des liens dynamiques pour l'iframe
      projects: [
        {
          title: 'MissJnails06',
          description: 'Institut de Formations',
          image: require('@/assets/portfolio/missjnails.png'),
          link: 'https://missjnails06.com'
        },
        {
          title: 'Naturel&Joli',
          description: 'Institut de Beauté',
          image: require('@/assets/portfolio/naturel&joli.png'),
          link: 'https://natureletjoli.fr/'
        },
        {
          title: 'ThiebHouse',
          description: 'Restaurant Africain',
          image: require('@/assets/portfolio/thiebhouse.png'),
          link: 'https://missjnails06.com'
        },
        {
          title: 'LuxuraBeauty',
          description: 'Site E-commerce',
          image: require('@/assets/portfolio/luxurabeauty.png'),
          link: 'https://LuxuraBeauty.store'
        },
        {
  title: 'Web App',
  description: 'Prise de RDV et affichage en temps réel dans des patients la salle d\'attente ',
  image: require('@/assets/portfolio/thierry.png'),
  link: ''  // Optionally, you can provide a URL if you want a link for this project.
}

      ],
      iframeVisible: false,  // Etat pour gérer l'affichage de l'iframe
      iframeUrl: ''  // L'URL du projet à afficher dans l'iframe
    };
  },
  methods: {
    // Fonction pour ouvrir un projet dans l'iframe
    openProject(link) {
      this.iframeUrl = link;   // Définir l'URL du projet
      this.iframeVisible = true; // Afficher l'iframe
    },
    // Fonction pour fermer la modal
    closeModal() {
      this.iframeVisible = false; // Masquer la modal
      this.iframeUrl = ''; // Réinitialiser l'URL de l'iframe
    }
  }
};
</script>

<style scoped>
  .glow {
    position: relative;
    z-index: 1; /* Carte au-dessus de la lueur */
  }
  
  .glow::before {
    content: '';
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    background: linear-gradient(45deg, rgba(255, 0, 150, 0.5), rgba(0, 229, 255, 0.5));
    filter: blur(8px);
    z-index: -1; /* Lueur derrière la carte */
    border-radius: 10px;
  }
.project-card {
  background-color: #1a1c2b;
  border-radius: 12px;
  overflow: hidden;
  transition: transform 0.3s;
}
.project-card:hover {
  transform: scale(1.05);
}
.project-card img {
  width: 100%;
  height: auto;
}
.project-card .content {
  padding: 16px;
}
.project-card .title {
  font-size: 1.25rem;
  font-weight: 600;
}
.project-card .description {
  font-size: 0.875rem;
  color: #a1a1aa;
}
.project-card .button {
  background-color: white;
  color: #d862b4;
  padding: 8px 16px;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  margin-top: 16px;
  text-decoration: none;
}
.project-card .button i {
  margin-left: 8px;
}

/* Styles pour la modal */
.fixed {
  position: fixed;
}
.bg-opacity-50 {
  background-color: rgba(0, 0, 0, 0.5);
}
.z-50 {
  z-index: 50;
}
.bg-white {
  background-color: white;
}
.shadow-lg {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.rounded-lg {
  border-radius: 10px;
}
.text-gray-600 {
  color: #4b5563;
}
button {
  cursor: pointer;
}
</style>
