<template>
    <div class="relative bg-black from-indigo-900 to-indigo-800 text-white min-h-screen overflow-hidden">
      <!-- Effet cercle en arrière-plan -->
      <div class="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 w-[700px] h-[700px] rounded-full blur-3xl opacity-70 z-0"
        style="background: radial-gradient(circle, #6DDCFF, #D862B4, #7F60F9);">
      </div>
  
      <!-- Contenu principal avec un overlay pour bien voir le texte -->
      <div class="relative z-10 container mx-auto py-16 text-center px-4">
        <h2 class="text-3xl md:text-5xl font-bold mb-8">Web App & E-commerce : Transformez Votre Entreprise</h2>
        <p class="text-lg mb-6 text-gray-400">Nous créons des sites web professionnels, modernes et adaptés à vos besoins.</p>
  
        <div class="flex justify-center gap-16 mb-12">
          <div class="text-left bg-black bg-opacity-60 p-8 rounded-lg w-full md:w-2/3 lg:w-1/2">
            <h3 class="text-2xl font-semibold mb-4">Pourquoi Choisir Nos Services ?</h3>
            <p class="text-lg mb-6 text-gray-300">Nous offrons des solutions personnalisées, adaptées à votre activité et à vos objectifs. Que vous ayez besoin d'un site vitrine, d'un e-commerce, ou d'une application web, nous avons la solution qui correspond à vos besoins.</p>
  
            <h4 class="text-xl font-semibold mb-2">Nos Atouts :</h4>
            <ul class="list-inside list-disc text-gray-300 mb-8">
              <li><i class="fas fa-check text-green-400"></i> Design moderne et responsive, adapté à tous les appareils.</li>
              <li><i class="fas fa-check text-green-400"></i> Optimisation SEO pour un meilleur classement sur les moteurs de recherche.</li>
              <li><i class="fas fa-check text-green-400"></i> Intégration d'outils de marketing numérique pour booster votre visibilité.</li>
              <li><i class="fas fa-check text-green-400"></i> Solutions sécurisées et performantes pour garantir une expérience utilisateur optimale.</li>
              <li><i class="fas fa-check text-green-400"></i> Support et maintenance continue pour assurer la pérennité de votre projet.</li>
            </ul>
  
            <h4 class="text-xl font-semibold mb-2">Les Types de Web App ou Ecommerce que Nous Créons :</h4>
            <ul class="list-inside list-disc text-gray-300">
              <li><i class="fas fa-check text-green-400"></i> Sites vitrines professionnels et dynamiques.</li>
              <li><i class="fas fa-check text-green-400"></i> Plateformes e-commerce personnalisées pour la vente en ligne.</li>
              <li><i class="fas fa-check text-green-400"></i> Applications web sur-mesure, adaptées à vos besoins spécifiques.</li>
            </ul>
  
            <p class="text-lg mt-8 text-gray-400">Nous mettons l'accent sur la performance, l'esthétique et l'expérience utilisateur pour chaque projet.</p>
          </div>
        </div>
  
        <!-- Appel à l'action -->
        <div class="flex justify-center">
          <button class="bg-[#d862b4] text-white py-2 px-4 rounded-full transition duration-300 hover:bg-pink-600">Discutez de Mon Projet</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  
  
  export default {
  
    methods: {
     
    }
  }
  </script>
  
  <style scoped>
  /* Ajout d'animations simples pour rendre l'interface plus dynamique */
  .step:hover {
    transform: scale(1.05);
  }
  </style>
  
  
