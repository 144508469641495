<template>
    <div class="min-h-screen flex items-center justify-center relative overflow-hidden">
     <!-- Layer avec effet de blur -->
     <div class="absolute inset-0 flex justify-center items-center">
       <div class="w-[60%] sm:w-[90%] md:w-[60%] h-[40%] sm:h-[60%] md:h-[60%] rounded-lg blur-2xl opacity-70 mx-auto"
         style="background: linear-gradient(135deg, #6DDCFF, #D862B4, #7F60F9);">
       </div>
     </div>
   
     <!-- Carte de contact -->
     <div class="relative bg-gray-800 rounded-lg shadow-lg p-6 md:p-8 max-w-4xl w-full grid grid-cols-1 lg:grid-cols-2 gap-8">
       <!-- Informations de contact -->
       <div>
         <h1 class="text-3xl md:text-4xl font-semibold mb-4 text-white">Contactez-nous</h1>
         <p class="mb-6 text-white">Chez DATAFUSE, nous vous accompagnons dans la création de solutions numériques innovantes pour automatiser vos processus, améliorer votre visibilité en ligne et transformer votre entreprise grâce à l'IA. Contactez-nous pour discuter de votre projet et comment nous pouvons vous aider à atteindre vos objectifs.</p>
         <div class="space-y-4 text-white">
           <div class="flex items-start">
             <i class="fas fa-building text-xl mr-4"></i>
             <div>
               
               <p>Paris</p>
             </div>
           </div>
           <div class="flex items-center">
             <i class="fas fa-phone text-xl mr-4"></i>
             <p>+33 7 82 54 07 51</p>
           </div>
           <div class="flex items-center">
             <i class="fas fa-envelope text-xl mr-4"></i>
             <p>contact@datafuse.fr</p>
           </div>
         </div>
       </div>
   
       <!-- Formulaire de contact -->
       <div>
         <form @submit.prevent="submitForm" class="space-y-4">
           <!-- Informations personnelles -->
           <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
             <div>
               <label for="first-name" class="block text-sm font-medium text-white">Prénom</label>
               <input type="text" id="first-name"
                 class="mt-1 block w-full bg-gray-700 border border-gray-600 rounded-md py-2 px-3 text-white focus:outline-none focus:ring-2 focus:ring-blue-500">
             </div>
             <div>
               <label for="last-name" class="block text-sm font-medium text-white">Nom</label>
               <input type="text" id="last-name"
                 class="mt-1 block w-full bg-gray-700 border border-gray-600 rounded-md py-2 px-3 text-white focus:outline-none focus:ring-2 focus:ring-blue-500">
             </div>
           </div>
           <div>
             <label for="email" class="block text-sm font-medium text-white">Email</label>
             <input type="email" id="email"
               class="mt-1 block w-full bg-gray-700 border border-gray-600 rounded-md py-2 px-3 text-white focus:outline-none focus:ring-2 focus:ring-blue-500">
           </div>
           <div>
             <label for="phone-number" class="block text-sm font-medium text-white">Numéro de téléphone</label>
             <input type="text" id="phone-number"
               class="mt-1 block w-full bg-gray-700 border border-gray-600 rounded-md py-2 px-3 text-white focus:outline-none focus:ring-2 focus:ring-blue-500">
           </div>
           <div>
             <label for="project-type" class="block text-sm font-medium text-white">Type de projet</label>
             <select id="project-type"
               class="mt-1 block w-full bg-gray-700 border border-gray-600 rounded-md py-2 px-3 text-white focus:outline-none focus:ring-2 focus:ring-blue-500">
               <option value="website">Site Web</option>
               <option value="ecommerce">E-commerce</option>
               <option value="saas">SaaS</option>
               <option value="automations">Automatisations</option>
               <option value="other">Autre</option>
             </select>
           </div>
           <div>
             <label for="budget" class="block text-sm font-medium text-white">Plage de budget</label>
             <select id="budget"
               class="mt-1 block w-full bg-gray-700 border border-gray-600 rounded-md py-2 px-3 text-white focus:outline-none focus:ring-2 focus:ring-blue-500">
               <option value="500-1000">500€ - 1000€</option>
               <option value="1000-5000">1000€ - 5000€</option>
               <option value="5000-10000">5000€ - 10000€</option>
               <option value="10000+">Plus de 10000€</option>
             </select>
           </div>
           <div>
             <button type="submit"
               class="w-full bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500">Envoyer
               le message</button>
           </div>
         </form>
       </div>
     </div>
   </div>
   
   </template>
   
  
  
  <script>
  import { ref } from 'vue';
  
  export default {
    name: 'ContactForm',
    setup() {
      const form = ref({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        message: ''
      });
  
      const submitForm = () => {
        console.log('Form submitted:', form.value);
        // Vous pouvez ajouter ici la logique d'envoi du formulaire
      };
  
      return {
        form,
        submitForm
      };
    }
  };
  </script>
  
  <style scoped>
  body {
    font-family: 'Inter', sans-serif;
  }
  </style>
  