<template>
  <div class="relative bg-black from-indigo-900 to-indigo-800 text-white min-h-screen overflow-hidden">
    <!-- Effet cercle en arrière-plan -->
    <div class="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 w-[700px] h-[700px] rounded-full blur-3xl opacity-70 z-0"
      style="background: radial-gradient(circle, #6DDCFF, #D862B4, #7F60F9);">
    </div>

    <!-- Contenu principal avec un overlay pour bien voir le texte -->
    <div class="relative z-10 container mx-auto py-16 text-center px-4">
      <h2 class="text-3xl md:text-5xl font-bold mb-8">À propos de Datafuse</h2>
      <p class="text-lg mb-6 text-gray-400">Nous transformons votre entreprise avec des solutions IA sur mesure, adaptées à vos besoins spécifiques.</p>

      <div class="flex flex-wrap justify-center gap-16 mb-12">
        <!-- Section Histoire -->
        <div class="bg-black bg-opacity-60 p-8 rounded-lg w-full md:w-1/2 lg:w-1/3 mb-8">
          <h3 class="text-2xl font-semibold mb-4">Notre Histoire</h3>
          <p class="text-lg text-gray-300 mb-6">Fondée avec la vision de révolutionner la manière dont les entreprises abordent leurs processus grâce à l'intelligence artificielle, Datafuse est née d'une passion pour l'innovation et l'efficacité.</p>
          <img src="https://via.placeholder.com/350x200" alt="Image d'histoire" class="rounded-lg shadow-lg mb-4">
          <p class="text-lg text-gray-400">Depuis sa création, Datafuse a accompagné des dizaines d'entreprises à travers le monde pour les aider à automatiser leurs processus et améliorer leur productivité.</p>
        </div>

        <!-- Section Valeurs -->
        <div class="bg-black bg-opacity-60 p-8 rounded-lg w-full md:w-1/2 lg:w-1/3 mb-8">
          <h3 class="text-2xl font-semibold mb-4">Nos Valeurs</h3>
          <ul class="list-inside list-disc text-gray-300 mb-8">
            <li><i class="fas fa-check text-green-400"></i> Innovation continue pour rester à la pointe de la technologie.</li>
            <li><i class="fas fa-check text-green-400"></i> Transparence et communication claire avec nos clients.</li>
            <li><i class="fas fa-check text-green-400"></i> Solutions personnalisées pour répondre à chaque besoin spécifique.</li>
            <li><i class="fas fa-check text-green-400"></i> Responsabilité sociale et engagement envers l'éthique de l'IA.</li>
          </ul>
        </div>

        <!-- Section Chiffres Clés -->
        <div class="bg-black bg-opacity-60 p-8 rounded-lg w-full md:w-1/2 lg:w-1/3 mb-8">
          <h3 class="text-2xl font-semibold mb-4">Nos Chiffres Clés</h3>
          <div class="flex justify-between text-center">
            <div>
              <p class="text-4xl font-bold text-pink-500 mb-2">95%</p>
              <p class="text-lg text-gray-300">Efficacité améliorée grâce à l'IA</p>
            </div>
            <div>
              <p class="text-4xl font-bold text-pink-500 mb-2">30%</p>
              <p class="text-lg text-gray-300">Gain de temps sur les tâches répétitives</p>
            </div>
            <div>
              <p class="text-4xl font-bold text-pink-500 mb-2">200+</p>
              <p class="text-lg text-gray-300">Projets d'automatisation réussis</p>
            </div>
          </div>
        </div>
      </div>

      <!-- Appel à l'action -->
      <div class="flex justify-center">
        <button class="bg-[#d862b4] text-white py-2 px-4 rounded-full transition duration-300 hover:bg-pink-600">
          Discutez de Mon Projet IA
        </button>
      </div>
    </div>
  </div>
</template>