<template>
  <div class="bg-black " id="app">


          <!-- Header Section -->
          <div  class="relative">
           
            <img class="absolute h-[100%] object-cover w-auto left-1/2 transform -translate-x-1/2 " src="../assets/back-5.gif">

    <div  class="relative z-10 text-center py-20">
      <!-- Overlay pour l'opacité -->
      <div class="absolute inset-0 bg-black opacity-60"></div>

      <!-- Contenu principal -->
      <header class="relative z-10 fade-in">
        <button class="bg-gray-800 text-white px-4 py-2 rounded-full mb-4" @click="openModal">
          Plans flexibles pour vous →
        </button>
        <h1 class="text-3xl sm:text-4xl md:text-5xl text-white font-bold mb-4">
          Solutions IA, sites web et <br> applications mobiles sur mesure
        </h1>
        <p class="text-white text-base sm:text-lg mb-8">
          Obtenez des devis gratuitement en quelques minutes
        </p>
        <Invoice_Bar />
        <!-- Section des avis -->
        <div class="flex items-center justify-center flex-wrap">
          <div class="flex -space-x-4 flex-wrap justify-center mb-4">
            <img
              alt="Person 1"
              class="w-12 h-12 sm:w-16 sm:h-16 rounded-full border-2 border-black"
              src="https://lh3.googleusercontent.com/-yVtfrgu2mdk/AAAAAAAAAAI/AAAAAAAAAAA/AMZuucnKdYBveHMFd5SMN3VfQYaJx6M5uA/photo.jpg"
            />
            <img
              alt="Person 2"
              class="w-12 h-12 sm:w-16 sm:h-16 rounded-full border-2 border-black"
              src="https://lh3.googleusercontent.com/a-/ALV-UjXTkrfnvXFrdMssqA2Lo96-43tm5sJKlL68LSZr5iz_heKjnuI-RA=s72-c-rp-mo-br100"
            />
            <img
              alt="Person 3"
              class="w-12 h-12 sm:w-16 sm:h-16 rounded-full border-2 border-black"
              src="https://lh3.googleusercontent.com/a/ACg8ocIQ5YdZxTrl7iNbqs08uU4EhXUIdEWHlc1NP_L-z4ll8Qra2g=s72-c-rp-mo-br100"
            />
            <img
              alt="Person 4"
              class="w-12 h-12 sm:w-16 sm:h-16 rounded-full border-2 border-black"
              src="https://lh3.googleusercontent.com/a-/ALV-UjVcbqdC6kYV05G6pOM6JDmFTc-eXhzgoCInirh-zQuISh7Joxo=s64-c-rp-mo-br100"
            />
          </div>

          <!-- Étoiles et texte -->
          <div class="flex flex-col items-center space-y-2 text-white text-sm sm:text-lg">
            <div class="flex space-x-1 text-orange-500">
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
            </div>
            <span>200+ Avis 5 étoiles</span>
          </div>
        </div>

        <a href="https://calendly.com/contact-datafuse/consultation-de-projet-avec-datafuse">
          <button class="bg-blue-600 text-white px-6 py-3 rounded-full mt-5" @click="openModal">
            Prendre Rendez-vous avec un développeur
          </button>
        </a>
            <!-- Tools Section -->
    <section class="text-center pt-20">
  <div ref="imageContainer" class="flex justify-center space-x-4 overflow-hidden">
    <img
      v-for="(image, index) in images"
      :key="index"
      :src="image"
      alt="Landing page screenshot"
      class="rounded-lg  transition-transform duration-500 img_portfolio_carroussel"
      ref="image"
    />
  </div>
</section>
      </header>
    </div>
  </div>
<ServicesDetails />
<OurMission />
<ProcessSteps />
<portfolio />
<pricing />
<QuestionA />
<FaQ />


<!-- Nos Services -->

<div>
  
</div>
  </div>



</template>

<script>


import Invoice_Bar from '@/components/Invoice_Bar.vue';
import portfolio from '../components/PortfolioVue.vue';
import pricing from '../components/PricingVue.vue';
import ProcessSteps from '@/components/ProcessSteps.vue';
import ServicesDetails from '@/components/ServicesDetails.vue';
import OurMission from '@/components/OurMission.vue';
import FaQ from '@/components/FaQ.vue';
import QuestionA from '@/components/QuestionA.vue';

export default {
  components: {
    portfolio,
    pricing,
    ProcessSteps,
    ServicesDetails,
    OurMission,
    FaQ,
    QuestionA,
    Invoice_Bar

  },
  name: 'App',
  data() {
    return {
      showVideo: true,
      menuOpen: false,
      isLoading: true,
      progress: 0,
      componentsToLoad: [portfolio, pricing, ProcessSteps, ServicesDetails, OurMission, FaQ, QuestionA], 
      images: [
      require('@/assets/portfolio/missjnails.png'),
      require('@/assets/portfolio/naturel&joli.png'),
      require('@/assets/portfolio/thiebhouse.png'),
      require('@/assets/portfolio/XtremeLove.png'),
      require('@/assets/portfolio/ocadodor.png'),
      require('@/assets/portfolio/luxurabeauty.png'),
      ],
      isModalOpen: false,
      step: 1,
      form: {
      service: '',
      webDevelopmentType: '',
      mobileDevelopmentType: '',
      softwareDevelopmentType: '',
      aiSolutionType: '',
      platform: '',
      projectDescription: '',
      budget: '',
      name: '',
      surname: '',
      email: '',
      phone: ''
    },
    };
  },
  mounted() {
    this.isModalOpen = true; // Open the modal when the page loads
    this.loadPage();

  },
  methods: {
    loadPage() {
      let totalItems = this.images.length + this.componentsToLoad.length; // Nombre total d'éléments à charger (images + composants)
      let loadedItems = 0;

      // Charge les images
      this.images.forEach((image) => {
        const img = new Image();
        img.src = image; // Charger l'image
        img.onload = () => {
          loadedItems++;
          this.updateProgress(loadedItems, totalItems); // Met à jour la barre de progression
        };
      });

      // Charge les composants
      this.componentsToLoad.forEach(( index) => {
        // Simuler le chargement d'un composant
        setTimeout(() => {
          loadedItems++;
          this.updateProgress(loadedItems, totalItems); // Met à jour la barre de progression
        }, 100 * index); // Vous pouvez ajuster ce délai en fonction de la complexité des composants
      });
    },
    updateProgress(loaded, total) {
      this.progress = Math.floor((loaded / total) * 100); // Calculer la progression en pourcentage
      if (this.progress >= 100) {
        this.isLoading = false; // Masquer l'écran de chargement lorsque tout est chargé
      }
    },

    
    openModal() {
  console.log("Modal opened");
  this.isModalOpen = true;
},
    hideVideo() {
      this.showVideo = false;
    },
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    nextStep(step) {
      this.step = step;
    },
    submitForm() {
      console.log('Form Submitted:', this.form);
      this.closeModal();
    },
  },
};
</script>

<style scoped>


.progress-bar {
    transition: width 0.2s ease-in-out;
  }
/* Modal background and styling */
.bg-opacity-50 {
  opacity: 0.5;
}

.bg-white {
  background-color: white;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.w-80 {
  width: 20rem;
}
body {
  font-family: 'Inter', sans-serif;
  background-color: #0b0d1a;
  color: #ffffff;
}

#app {
  overflow: hidden;
}

.img_portfolio_carroussel {
  width: 400px; /* Ajustez cette valeur en fonction de la taille de vos images */
  height: auto;
  animation: scrollImages 20s linear infinite;
}

@keyframes scrollImages {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

section {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.flex {
  display: flex;
  flex-wrap: nowrap;
}

.overflow-hidden {
  overflow: hidden;
}


@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  animation: fadeIn 1.5s ease-out;
}

</style>


