<template>
    <div class="flex flex-col items-center p-8 bg-black text-white">
      <div class="text-center mb-8">
        <button class="bg-transparent text-gray-400 py-2 px-4 rounded-full border border-gray-400 mb-4">
          Nos Services
        </button>
        <h1 class="text-4xl font-semibold mb-4">
          Expertise Qui Garantie la Qualité
        </h1>
        <p class="text-gray-400">
          Grâce à notre expertise, nous offrons des solutions de qualité qui favorisent le succès et dépassent constamment les standards de l'industrie.
        </p>
      </div>
      
      <div class="flex flex-col md:flex-row justify-center gap-8 mb-8">
        <div class="card w-80">
          <img :src="services[0].imgSrc" :alt="services[0].altText" class="mb-4 rounded-lg" height="200" width="300"/>
          <h2 class="text-xl font-semibold mb-2">{{ services[0].title }}</h2>
          <p class="text-gray-400">{{ services[0].description }}</p>
        
        
        </div>
        <div class="card w-80">
          <img :src="services[1].imgSrc" :alt="services[1].altText" class="mb-4 rounded-lg" height="200" width="300"/>
          <h2 class="text-xl font-semibold mb-2">{{ services[1].title }}</h2>
          <p class="text-gray-400">{{ services[1].description }}</p>
       
        
        </div>
        <div class="card w-80">
          <img :src="services[2].imgSrc" :alt="services[2].altText" class="mb-4 rounded-lg" height="200" width="300"/>
          <h2 class="text-xl font-semibold mb-2">{{ services[2].title }}</h2>
          <p class="text-gray-400">{{ services[2].description }}</p>
          
        
        </div>
      </div>
      
      <div class="flex flex-wrap justify-center">
        <button class="icon-button text-gray-400" v-for="(button, index) in iconButtons" :key="index">
          <i :class="button.icon"></i>
          {{ button.label }}
        </button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'App',
    data() {
      return {
        services: [
          {
            title: "Pack Site Web Basique",
            description: "Design responsive (1 à 5 pages), Optimisation SEO de base, Hébergement inclus 1 an, Formulaire de contact.",
            price: "60€ / mois",
            imgSrc: require('@/assets/SEO.gif'),
            altText: "Image Pack Site Web Basique"
          },
          {
            title: "Pack E-commerce & Web App",
            description: "Boutique en ligne complète, Intégration paiement sécurisé, Gestion des stocks et des commandes, Application web sur mesure.",
            price: "500€ / mois",
            imgSrc: require('@/assets/Coding.gif'),
            altText: "Image Pack E-commerce & Web App"
          },
          {
  title: "Pack Automatisation IA",
  description: "Automatisation des tâches répétitives, Développement de chatbot IA, Analyse prédictive des données, Génération automatique de rapports.",
  price: "300€ / mois",
  imgSrc: require('@/assets/AI.gif'), // Image locale dans le dossier assets
  altText: "Image Pack Automatisation IA"
}
        ],
        iconButtons: [
          { icon: 'fas fa-bolt', label: 'Solutions Basées sur l\'IA' },
          { icon: 'fas fa-server', label: 'Informatique Sans Serveur' },
          { icon: 'fas fa-cloud', label: 'Intégration Cloud' },
          { icon: 'fas fa-chart-line', label: 'Analyse de Données' },
          { icon: 'fas fa-chart-pie', label: 'Analytique' },
          { icon: 'fas fa-shield-alt', label: 'Sécurité API' },
          { icon: 'fas fa-clock', label: 'Temps Réel' },
          { icon: 'fas fa-bullseye', label: 'Ciblage Publicitaire' }
        ]
      };
    }
  };
  </script>
  
  <style scoped>
  body {
    font-family: 'Inter', sans-serif;
    background-color: #0d0d0d;
    color: #ffffff;
  }
  .card {
    background-color: #1a1a1a;
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;
  }
  .icon-button {
    background-color: #1a1a1a;
    border: 1px solid #333;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.5rem;
  }
  .icon-button i {
    margin-right: 0.5rem;
  }
  </style>
  