<template>
  <div class="bg-black">
    <portfolio />
  </div>

</template>

<script>
import portfolio from '../components/PortfolioVue.vue';

export default {
  components: {
    portfolio,
  },
};
</script>