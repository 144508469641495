<template>
  <div>
    <!-- Notification -->
    <div class="bg-gradient-to-r from-[#d862b4] to-[#6a0dad] text-white py-2 px-4 text-center font-semibold">
      <span>🚀 Promotion BlackFriday -20% sur votre projet </span>
      <a href="https://calendly.com/contact-datafuse/consultation-de-projet-avec-datafuse" class="ml-2 underline hover:text-blue-300">En savoir plus</a>
    </div>

    <!-- Navigation -->
    <nav class="flex justify-between items-center py-6 px-10 bg-[rgb(0,2,15)] sticky top-0 z-50">
      <div class="text-2xl font-bold">
        <img src="@/assets/datafuse.svg" alt="DATAFUSE Logo" class="h-12 w-auto" />
      </div>

      <!-- Menu de navigation (desktop) -->
      <ul class="flex space-x-8 mx-auto hidden md:flex">
    <li><a class="text-white hover:text-[#d862b4]" href="/">Acceuil</a></li>
    <li class="relative" @click="toggleDropdown">
      <a class="text-white hover:text-[#d862b4]" href="#">Nos Services</a>
      <ul v-show="showDropdown" 
          class="absolute left-0 mt-2 space-y-2 bg-[#111] text-white rounded-lg shadow-lg transition-all ease-in-out duration-300">
        <li><a class="block px-4 py-2 hover:bg-[#d862b4]" href="WebsiteCreation">Création de Sites Web</a></li>
        <li><a class="block px-4 py-2 hover:bg-[#d862b4]" href="WebApp">Web APP / E-commerce</a></li>
        <li><a class="block px-4 py-2 hover:bg-[#d862b4]" href="AutomatisationIA">Solutions IA & Automatisation</a></li>
      </ul>
    </li>
    <li><a class="text-white hover:text-[#d862b4]" href="/portfolio">Portfolio</a></li>
    <li><a class="text-white hover:text-[#d862b4]" href="#">A Propos</a></li>
  </ul>

      <div class="hidden md:block">
        <a class="text-white hover:text-gray-400 bg-blue-600 px-4 py-2 rounded-full" href="https://calendly.com/contact-datafuse/consultation-de-projet-avec-datafuse">Obtenir un Devis</a>
      </div>

      <!-- Hamburger Button -->
      <div class="md:hidden flex items-center" @click="toggleMenu">
        <button class="text-white focus:outline-none">
          <svg v-if="!menuOpen" class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
          </svg>
          <svg v-if="menuOpen" class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>
      </div>
    </nav>

    <!-- Menu mobile (volet animé) -->
    <div :class="{'transform translate-x-full': !menuOpen, 'transform translate-x-0': menuOpen}" class="fixed top-0 right-0 w-3/4 h-full bg-black  transition-transform duration-300 ease-in-out md:hidden">
      <!-- Bouton de fermeture -->
      <button @click="toggleMenu" class="absolute top-4 right-4 text-white w-6 h-6">
        <svg class="w-full h-full" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
        </svg>
      </button>

      <!-- Liens de navigation -->
      <ul class="flex flex-col items-center space-y-4 mt-24">
    <li><a class="text-white hover:text-[#d862b4]" href="/">Acceuil</a></li>
    <li class="relative" @click="toggleSubMenu" :aria-expanded="submenu ? 'true' : 'false'">
      <a class="text-white hover:text-[#d862b4]" href="javascript:void(0)">
        Nos Services
        <!-- Chevron Icon from Heroicons -->
        <ChevronDownIcon :class="{'transform rotate-180': submenu}" class="ml-2 transition-transform duration-300 w-5 h-5"/>
      </a>
      <ul v-show="submenu" class="space-y-2 bg-[#111] text-white rounded-lg overflow-hidden max-h-0 transition-all duration-300 ease-in-out" :class="{'max-h-96': submenu}">
        <li><a class="block px-4 py-2 hover:bg-[#d862b4]" href="WebsiteCreation">Création de Sites Web</a></li>
        <li><a class="block px-4 py-2 hover:bg-[#d862b4]" href="WebApp">Web APP / E-commerce</a></li>
        <li><a class="block px-4 py-2 hover:bg-[#d862b4]" href="AutomatisationIA">Solutions IA & Automatisation</a></li>
      </ul>
    </li>
    <li><a class="text-white hover:text-[#d862b4]" href="/portfolio">Portfolio</a></li>
    <li><a class="text-white hover:text-[#d862b4]" href="#">A Propos</a></li>
    <li><a class="text-white hover:text-gray-400 bg-blue-600 px-4 py-2 rounded-full" href="#">Contact Us</a></li>
  </ul>

    </div>

    <router-view></router-view>
    <footer class="bg-[rgb(0,2,15)] text-white py-10 px-4">
    <div class="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-4 gap-6">
      <!-- Colonne 1 : Logo et Description -->
      <div>
        <img src="@/assets/datafuse.svg" alt="DATAFUSE Logo" class="h-12 w-auto mb-4" />
        <p class="text-gray-400">
          DATAFUSE - Votre partenaire pour des solutions IA et des sites web optimisés.
          Boostez votre présence en ligne dès aujourd'hui.
        </p>
      </div>

      <!-- Colonne 2 : Liens rapides -->
      <div>
        <h3 class="font-semibold text-[#d862b4] mb-4">Liens Rapides</h3>
        <ul class="space-y-2">
          <li><a href="/" class="hover:text-[#d862b4]">Accueil</a></li>
          <li><a href="#" class="hover:text-[#d862b4]">Nos Services</a></li>
          <li><a href="/portfolio" class="hover:text-[#d862b4]">Portfolio</a></li>
          <li><a href="#" class="hover:text-[#d862b4]">À Propos</a></li>
        </ul>
      </div>

      <!-- Colonne 3 : Services -->
      <div>
        <h3 class="font-semibold text-[#d862b4] mb-4">Nos Services</h3>
        <ul class="space-y-2">
          <li><a href="#" class="hover:text-[#d862b4]">Solutions IA</a></li>
          <li><a href="#" class="hover:text-[#d862b4]">Automatisation</a></li>
          <li><a href="#" class="hover:text-[#d862b4]">Marketing Digital</a></li>
          <li><a href="#" class="hover:text-[#d862b4]">Création de Sites Web</a></li>
        </ul>
      </div>

      <!-- Colonne 4 : Contact -->
      <div>
        <h3 class="font-semibold text-[#d862b4] mb-4">Contact</h3>
        <ul class="space-y-2 text-gray-400">
          <li>Email : <a href="mailto:contact@datafuse.fr" class="hover:text-[#d862b4]">contact@datafuse.fr</a></li>
          <li>Téléphone : <a href="tel:+33782540751" class="hover:text-[#d862b4]">+33 7 82 54 07 51</a></li>
          <li>Adresse : Paris, France</li>
        </ul>
        <div class="mt-4">
          <a href="#" class="text-white hover:text-gray-400 bg-blue-600 px-4 py-2 rounded-full">Obtenir un Devis</a>
        </div>
      </div>
    </div>

    <!-- Bas de page -->
    <div class="mt-10 border-t border-gray-700 pt-4 text-center">
      <p class="text-gray-500 text-sm">
        © 2024 DATAFUSE. Tous droits réservés.
      </p>
    </div>
  </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuOpen: false,
      submenu: false,
      showDropdown: false
    };
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown; // Change l'état de showDropdown à chaque clic
    },
    toggleSubMenu() {
      this.submenu = !this.submenu; // Alterner l'état du sous-menu
    },
  },
};
</script>

<style scoped>
/* Optionnel: Ajout d'une ombre douce pour le menu mobile */
.md\:hidden {
  z-index: 60;
}
</style>


<style scoped>
body {
  font-family: 'Poppins', sans-serif;
  background-color: #0b0b0d;
  color: white;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
}

nav a.router-link-exact-active {
  color: #d862b4; /* Couleur active définie */
}


/* Transition de l'opacité */
.transition-opacity {
  transition: opacity 1s ease-in-out;
}
</style>
