<template>
    <div class="relative bg-black from-indigo-900 to-indigo-800 text-white min-h-screen overflow-hidden">
      <!-- Effet cercle en arrière-plan -->
      <div class="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 w-[700px] h-[700px] rounded-full blur-3xl opacity-70 z-0"
        style="background: radial-gradient(circle, #6DDCFF, #D862B4, #7F60F9);">
      </div>
  
      <!-- Contenu principal avec un overlay pour bien voir le texte -->
      <div class="relative z-10 container mx-auto py-16 text-center px-4">
        <h2 class="text-3xl md:text-5xl font-bold mb-8">Automatisation IA : Optimisez Votre Entreprise</h2>
        <p class="text-lg mb-6 text-gray-400">Automatisez vos processus et améliorez l'efficacité grâce à l'intelligence artificielle.</p>
  
        <div class="flex justify-center gap-16 mb-12">
          <div class="text-left bg-black bg-opacity-60 p-8 rounded-lg w-full md:w-2/3 lg:w-1/2">
            <h3 class="text-2xl font-semibold mb-4">Pourquoi Choisir l'Automatisation IA ?</h3>
            <p class="text-lg mb-6 text-gray-300">L'IA permet d'automatiser des tâches répétitives, d'analyser des données à grande échelle et d'optimiser les processus pour un gain de temps et d'efficacité considérable.</p>
  
            <h4 class="text-xl font-semibold mb-2">Nos Atouts :</h4>
            <ul class="list-inside list-disc text-gray-300 mb-8">
              <li><i class="fas fa-check text-green-400"></i> Automatisation des tâches répétitives pour libérer du temps précieux.</li>
              <li><i class="fas fa-check text-green-400"></i> Analyse prédictive pour prendre des décisions basées sur des données précises.</li>
              <li><i class="fas fa-check text-green-400"></i> Personnalisation des interactions avec les clients grâce à des chatbots intelligents.</li>
              <li><i class="fas fa-check text-green-400"></i> Optimisation des processus pour augmenter la productivité et réduire les coûts.</li>
              <li><i class="fas fa-check text-green-400"></i> Solutions sur-mesure adaptées à vos besoins spécifiques et à vos objectifs commerciaux.</li>
            </ul>
  
            <h4 class="text-xl font-semibold mb-2">Les Types d'Automatisation IA que Nous Proposons :</h4>
            <ul class="list-inside list-disc text-gray-300">
              <li><i class="fas fa-check text-green-400"></i> Automatisation des processus administratifs et financiers.</li>
              <li><i class="fas fa-check text-green-400"></i> Chatbots et assistants virtuels pour une gestion efficace de la relation client.</li>
              <li><i class="fas fa-check text-green-400"></i> Systèmes de recommandation basés sur les comportements clients pour améliorer l'engagement.</li>
              <li><i class="fas fa-check text-green-400"></i> Automatisation des flux de travail et des communications internes.</li>
            </ul>
  
            <p class="text-lg mt-8 text-gray-400">Avec l'automatisation IA, votre entreprise peut réduire les erreurs humaines, améliorer la réactivité et se concentrer sur des tâches à plus forte valeur ajoutée.</p>
          </div>
        </div>
  
        <!-- Appel à l'action -->
        <div class="flex justify-center">
          <button class="bg-[#d862b4] text-white py-2 px-4 rounded-full transition duration-300 hover:bg-pink-600">Discutez de Mon Projet IA</button>
        </div>
      </div>
    </div>
  </template>
  