<template>
<div class="bg-black text-white font-roboto flex items-center justify-center min-h-screen px-4 relative overflow-hidden">
  <!-- Cercle avec effet de blur -->
  <div class="absolute inset-0 flex justify-center items-center">
    <div class="w-[500px] h-[500px] rounded-full blur-3xl opacity-70"
      style="background: radial-gradient(circle, #6DDCFF, #D862B4, #7F60F9);">
    </div>
  </div>

  <!-- Contenu principal -->
  <div class="relative text-center max-w-3xl mx-auto">
    <div class="mb-6">
      <button class="bg-transparent border border-white text-white py-2 px-6 rounded-full text-lg">
        Nos Services
      </button>
    </div>
    <h1 class="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold leading-tight mb-4">
      Nous Aidons <span class="text-[#d862b4]">Les Entreprises</span><br />
      Grâce à Des Solutions <span class="text-[#d862b4]">Sur Mesure</span>
    </h1>
    <p class="mt-4 sm:mt-6 text-gray-400 text-base sm:text-lg md:text-xl">
      Grâce à notre expertise en automatisation et en IA, nous créons des solutions sur-mesure pour optimiser vos processus, améliorer l'efficacité et accélérer la croissance de votre entreprise. De la création de sites web à l'intégration d'applications intelligentes, nous vous accompagnons pour vous propulser vers le succès.
    </p>
    <div class="mt-6 sm:mt-8">
      <a href="#" class="text-[#d862b4] text-lg sm:text-xl font-semibold hover:underline">
        Réservez Un Appel <span class="inline-block transform translate-x-1">↗</span>
      </a>
    </div>
  </div>
</div>


  </template>
  
  
  <script>
  export default {
    name: 'MissionPage'
  };
  </script>
  
  <style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
  body {
    font-family: 'Roboto', sans-serif;
  }
  </style>
  