<template>
    <div class="p-8">
      <div class="text-center mb-8">

        <button class="bg-transparent text-gray-400 py-2 px-4 rounded-full border border-gray-400 mb-4">
            Lancez votre projet en 12H
        </button>
        <h1 class="section-title text-white">
          Processus de Création : Un Impact Durable
        </h1>
      </div>
      <div class="flex flex-col md:flex-row justify-between items-center">
        <div class="md:w-1/2 flex flex-col items-center">
          <div
            v-for="(step, index) in steps"
            :key="index"
            class="card flex-1 opacity-0 transition-opacity duration-1000 delay-500 step-card text-white"
          >
            <h2 class="card-title">{{ step.title }}</h2>
            <p class="card-content">{{ step.content }}</p>
          </div>
        </div>
        <div class="md:w-1/2 mt-8 md:mt-0 md:ml-8 flex justify-center">
          <div class="image-container flex-1 sticky top-0">
            <img
              alt="Équipe travaillant sur un ordinateur portable avec un graphique montrant une augmentation de 80%"
              class="rounded-lg object-cover h-full w-full"
              src="https://storage.googleapis.com/a1aa/image/Ao8aCB6zUQ7MPBEuo1bEoefRNKeiSAESSgw5h51AyOqVIgeOB.jpg"
            />
          </div>
        </div>
      </div>
      <div class="bg-black text-white">
    <div class="flex justify-center items-center mt-20 mb-10">
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8 text-center">
        <div>
          <p class="text-5xl font-semibold animate-countup">{{ stats.activeUsers }}</p>
          <p class="text-lg">Active Users</p>
        </div>
        <div class="border-l border-gray-600 pl-8">
          <p class="text-5xl font-semibold animate-countup">{{ stats.rating }}</p>
          <p class="text-lg">Rating out of 5</p>
        </div>
        <div class="border-l border-gray-600 pl-8">
          <p class="text-5xl font-semibold animate-countup">{{ stats.positiveReviews }}</p>
          <p class="text-lg">Positive Reviews</p>
        </div>
        <div class="border-l border-gray-600 pl-8">
          <p class="text-5xl font-semibold animate-countup">{{ stats.usersSatisfied }}</p>
          <p class="text-lg">Users Satisfied</p>
        </div>
      </div>
    </div>
  </div>
    </div>
  
  </template>
  
  
  <script>
  export default {
    name: "WorkSection",
    data() {
      return {
        stats: {
        activeUsers: "150k+",
        rating: "4.9",
        positiveReviews: "99k+",
        usersSatisfied: "85k+"
      },
        steps: [
          {
            title: "Étape 1: Premier Rendez-vous",
            content:
              "Nous organisons un premier rendez-vous pour comprendre vos besoins et définir ensemble la meilleure approche pour votre projet.",
          },
          {
            title: "Étape 2: Devis et Solution sur Mesure",
            content:
              "Vous recevez un devis détaillé ainsi qu'une solution sur mesure sous 2h, adaptée à vos objectifs et à vos attentes.",
          },
          {
            title: "Étape 3: Réalisation du Projet",
            content:
              "Notre équipe démarre la réalisation de votre projet web en suivant la stratégie définie lors de nos échanges.",
          },
          {
            title: "Étape 4: Suivi et Ajustements",
            content:
              "Vous suivez l'avancement du projet et pouvez demander des ajustements à tout moment pour garantir votre satisfaction.",
          },
          {
            title: "Étape 5: Présentation et Mise en Ligne",
            content:
              "Nous vous présentons le résultat final et mettons en ligne votre nouvelle plateforme pour que vous puissiez commencer à l'utiliser.",
          },
        ],
      };
    },
    mounted() {
      this.observeCards();
    },
    methods: {
      observeCards() {
        const options = {
          threshold: 0.5, // Déclenche l'apparition lorsque 50% de la carte est visible
        };
  
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add("opacity-100"); // Applique l'opacité 100 lorsque visible
            }
          });
        }, options);
  
        // Cibler toutes les cartes
        const cards = document.querySelectorAll(".step-card");
        cards.forEach((card) => observer.observe(card));
      },
    },
  };
  </script>
  <style scoped>
  body {
    font-family: "Roboto", sans-serif;
    background-color: #0d0d0d;
    color: #ffffff;
  }
  
  .highlight {
    color: #f97316;
  }
  
  .section-title {
    font-size: 2.5rem;
    font-weight: 700;
  }
  
  .sub-title {
    font-size: 1rem;
    font-weight: 400;
    color: #a3a3a3;
  }
  
  .card {
    background-color: #1a1a1a;
    border-radius: 0.5rem;
    padding: 1.5rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    opacity: 0; /* Initial opacity 0 */
    transition: opacity 1s ease-out; /* Transition d'opacité pour chaque carte */
  }
  
  .card-title {
    font-size: 1.25rem;
    font-weight: 700;
  }
  
  .card-content {
    font-size: 1rem;
    font-weight: 400;
    color: #a3a3a3;
  }
  
  .image-container {
    background-color: #1a1a1a;
    border-radius: 0.5rem;
    padding: 1rem;
    height: 100%;
  }
  
  /* Ajout de la classe pour une opacité à 100% */
  .step-card.opacity-100 {
    opacity: 1 !important;
    transition: opacity 1s ease-in-out;
  }

  @keyframes countup {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.animate-countup {
  animation: countup 1s ease-out;
}

/* Add responsive padding and spacing */
@media (max-width: 640px) {
  .grid-cols-1 {
    grid-template-columns: 1fr;
  }
  .text-5xl {
    font-size: 2.5rem;
  }
}

@media (min-width: 640px) {
  .grid-cols-2 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 768px) {
  .grid-cols-4 {
    grid-template-columns: repeat(4, 1fr);
  }
}
  </style>
  