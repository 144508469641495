import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PortfoilioVue from '@/views/PortfoilioVue.vue'
import WebsiteCreation from '@/views/WebsiteCreation.vue'
import WebApp from '@/views/WebApp.vue'
import AutomatisationIA from '@/views/AutomatisationIA.vue'
import AboutUs from '@/views/AboutView.vue'
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutUs
  },
  {
    path: '/AutomatisationIA',
    name: 'AutomatisationIA',
    component: AutomatisationIA
  },
  {
    path: '/WebApp',
    name: 'WebApp',
    component: WebApp
  },
  {
    path: '/WebsiteCreation',
    name: 'WebsiteCreation',
    component: WebsiteCreation
  },
  {
    path: '/portfolio',
    name: 'portfoilio',
    component: PortfoilioVue
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
