<template>
  <div class="flex flex-col items-center justify-center px-4 sm:px-6 lg:px-8">
  <!-- Barre de recherche -->
  <div class="flex flex-col sm:flex-row sm:justify-center items-center gap-2 mb-5 w-full">
  <div class="relative w-full sm:w-[400px]">
    <input 
      v-model="formData.searchQuery"
      @input="filterSuggestions"
      type="text" 
      placeholder="Quel service recherchez-vous ?" 
      class="w-full px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
      required
    />
    <!-- Liste des suggestions -->
    <ul v-if="filteredServices.length" class="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-md">
      <li 
        v-for="(service, index) in filteredServices" 
        :key="index" 
        @click="selectService(service)"
        class="px-4 py-2 cursor-pointer hover:bg-blue-100"
      >
        {{ service }}
      </li>
    </ul>
  </div>

  <input 
    type="text" 
    placeholder="Code postal" 
    class="w-full sm:max-w-[150px] px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
    required
  />
  
  <button 
    @click="openModal"
    class="px-4 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-1">
    Rechercher
  </button>
</div>


  <!-- Modal -->
  <div v-if="isModalOpen" class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
    <div class="bg-white rounded-lg w-[90%] max-w-xl">
      <div class="flex justify-between px-4 py-3 border-b">
        <h2 class="text-lg font-semibold">Formulaire de projet</h2>
        <button @click="closeModal" class="text-gray-600 hover:text-red-500">✕</button>
      </div>
      <div class="p-4">
        <!-- Formulaire Multi-Étapes -->
        <div v-if="step === 1">
          <h3 class="mb-4 text-lg font-semibold">Étape 1 : Informations personnelles</h3>
          <input 
            v-model="formData.name"
            type="text" 
            placeholder="Votre nom" 
            class="w-full mb-3 px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
            required
          />
          <input 
            v-model="formData.email"
            type="email" 
            placeholder="Votre email" 
            class="w-full mb-3 px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
            required
          />
          <input 
            v-model="formData.phone"
            type="tel" 
            placeholder="Votre téléphone" 
            class="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
            required
          />
        </div>
        <div v-if="step === 2">
  <h3 class="mb-4 text-lg font-semibold">Étape 2 : Détails du projet</h3>
  <textarea 
    v-model="formData.projectDetails"
    placeholder="Décrivez votre projet en quelques lignes..." 
    class="w-full h-32 mb-3 px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
    required
  ></textarea>
  
  <select 
    v-model="formData.budget"
    class="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
    required
  >
    <option value="" disabled>Quel est votre budget ?</option>
    <option value="0-500">0-500€</option>
    <option value="500-1000">500-1000€</option>
    <option value="1000-3000">1000-3000€</option>
    <option value="3000-5000">3000-5000€</option>
    <option value="5000+">5000€+</option>
    <option value="Autre : ">Autre</option>
  </select>

  <!-- Champ de saisie conditionnel pour l'option "Autre" -->
  <div v-if="formData.budget === 'Autre : '">
    <input 
      v-model="formData.customBudget"
      type="text"
      placeholder="Indiquez votre budget"
      class="w-full px-4 py-2 mt-4 border rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
      required
    />
  </div>

  <!-- Question sur le timing du projet -->
 
  <select 
    v-model="formData.projectStartTime"
    class="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none mt-4"
    required
  >
    <option value="" disabled>Dans combien de temps souhaitez-vous commencer votre projet ?</option>
    <option value="immediate">Dès que possible</option>
    <option value="1-2 weeks">Dans 1 à 2 semaines</option>
    <option value="1 month">Dans 1 mois</option>
    <option value="3 months">Dans 3 mois</option>
    <option value="later">Plus tard</option>
  </select>
</div>

        <div v-if="step === 3">
          <h3 class="mb-4 text-lg font-semibold">Étape 3 : Plateforme et Objectifs</h3>
          <select 
            v-model="formData.platform"
            class="w-full mb-4 px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
            required
          >
            <option value="" disabled>Quelle plateforme souhaitez-vous utiliser pour développer votre site ?</option>
            <option value="WordPress">WordPress (Sites vitrine, blogs, petites boutiques)</option>
            <option value="Shopify">Shopify (E-commerce)</option>
            <option value="Wix">Wix (Sites simples, rapidement déployés)</option>
            <option value="Développement sur mesure (Fonctionnalités avancées, projet personnalisé )">Développement sur mesure (Fonctionnalités avancées, projet personnalisé )</option>
            <option value="J'en discuterai avec le développeur">J'en discuterai avec le développeur</option>
          </select>

          <textarea 
            v-model="formData.siteObjectives"
            placeholder="Quels sont les objectifs de votre demande ? (ex: augmenter les ventes, générer des prospects, etc.)"
            class="w-full h-32 mb-3 px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
            required
          ></textarea>
        </div>
        <div v-if="step === 4">
  <h3 class="mb-4 text-lg font-semibold">Confirmation</h3>
  <img class="h-32 mx-auto" src="../assets/confirmation.gif">
  <p class="text-center text-lg font-semibold mb-4">Votre demande a été envoyée avec succès !</p>
  <p class="text-center mb-6">Pour mieux discuter de votre projet et planifier les étapes suivantes, prenez rendez-vous pour une visio avec notre développeur.</p>
  
  <!-- Bouton pour prendre rendez-vous -->
 
</div>

      </div>
      <div class="flex justify-between px-4 py-3 border-t items-center">
        <button 
          v-if="step > 1"
          @click="previousStep"
          class="px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300"
        >
          Précédent
        </button>
        
        <!-- Affichage de l'étape actuelle -->
        <span class="mx-4 text-gray-700">
          {{ step }}/4
        </span>
        
        <button 
          v-if="step < 3"
          @click="nextStep"
          class="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
        >
          Suivant
        </button>
        <a  v-if="step === 4" href="https://calendly.com/contact-datafuse/consultation-de-projet-avec-datafuse">
        <button 
         
         
          class="px-6 py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-300"
        >
        Planifier un Rendez-vous en Visio
       </button>
      </a>
       <button 
          v-if="step === 3"
          @click="submitForm"
          class="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700"
        >
          Soumettre
        </button>
      </div>
    </div>
  </div>
</div>

  </template>
  
  
  <script>
  import emailjs from "emailjs-com";
  export default {
    data() {
      return {
       
        filteredServices: [],
       
        services: [
  "Création de sites web",
  "Développement de web apps",
  "Automatisation des processus",
  "Solutions IA",
  "Marketing digital",
  "SEO",
  "Maintenance de sites web",
  "Gestion de contenu",
  "Intégration d'API",
  "Hébergement web",
  "Design UI/UX",
  "Optimisation des performances",
  "Audit SEO",
  "Création de landing pages",
  "Refonte de sites web",
  "Analyse des données web",
  "Campagnes publicitaires en ligne",
  "Gestion des réseaux sociaux",
  "Développement d'applications mobiles",
  "Création de boutiques en ligne",
  "Configuration CRM",
  "Chatbots automatisés",
  "Personnalisation d'expériences utilisateurs",
  "Rédaction de contenu optimisé SEO",
  "Tests de performance web",
  "Sécurisation des sites web",
  "Formation en marketing digital",
  "Gestion des campagnes Google Ads",
  "Email marketing automatisé",
  "Développement de logiciels sur mesure",
  "Migration de sites web",
  "Stratégie de branding numérique",
  "Mise en conformité RGPD",
],
        isModalOpen: false,
        step: 1,
        formData: {
          searchQuery: "",
          name: "",
          email: "",
          phone: "",
          projectDetails: "",
          budget: "",
          platform: "",  // Nouveau champ pour la plateforme
          siteObjectives: "", 
          projectStartTime: "",
          customBudget:""
        },
      };
    },
    methods: {
      async sendEmail() {
      const templateParams = {
        name: this.formData.name,
        email: this.formData.email,
        phone: this.formData.phone,
        projectDetails: this.formData.projectDetails,
        budget: this.formData.budget,
        platform: this.formData.platform,
        siteObjectives: this.formData.siteObjectives,
        projectStartTime: this.formData.projectStartTime,
        searchQuery: this.formData.searchQuery,
        customBudget: this.formData.customBudget
      };

      try {
        await emailjs.send(
          "service_w7jhwae", // Remplacez par votre ID de service EmailJS
          "template_uu9xbys", // Remplacez par l'ID de votre modèle
          templateParams,
          "OD57RBt2yPnS0uIqG" // Remplacez par votre ID utilisateur EmailJS
        );
        alert("Email envoyé avec succès !");
      } catch (error) {
        console.error("Erreur lors de l'envoi de l'email :", error);
        alert("Une erreur est survenue lors de l'envoi de l'email.");
      }
    },
      normalizeString(str) {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
      },
      filterSuggestions() {
        const normalizedQuery = this.normalizeString(this.formData.searchQuery);
        this.filteredServices = this.services.filter((service) =>
          this.normalizeString(service).includes(normalizedQuery)
        );
      },
      selectService(service) {
        this.formData.searchQuery = service;
        this.filteredServices = [];
      },
      openModal() {
  if (!this.formData.searchQuery) {
    alert("Veuillez choisir un service.");
    return; // Empêche l'ouverture du modal si aucun service n'a été sélectionné.
  }
  this.isModalOpen = true;
}
,
      closeModal() {
        this.isModalOpen = false;
        this.step = 1; // Réinitialiser le formulaire
      },
      nextStep() {
    if (this.isStepValid) {
      this.step++;
    } else {
      alert('Veuillez remplir tous les champs requis.');
    }
  },
      previousStep() {
        if (this.step > 1) this.step--;
      },
      async submitForm() {
      // Envoyer les données par email
      await this.sendEmail();
      this.nextStep();
      
      // Réinitialiser et fermer le modal
     
    },
    },
    computed: {
  // Vérifier si les champs de l'étape actuelle sont remplis
  isStepValid() {
    if (this.step === 1) {
      return this.formData.name && this.formData.email && this.formData.phone;
    } else if (this.step === 2) {
      return this.formData.projectDetails && this.formData.budget && this.formData.projectStartTime;
    } else if (this.step === 3) {
      return this.formData.platform && this.formData.siteObjectives;
    }
    return true; // Par défaut, valider la dernière étape
  }
},
  };
  </script>
  
  <style>
  /* Ajoutez des styles personnalisés si nécessaire */
  </style>
  