<template>
<div class="relative bg-black from-indigo-900 to-indigo-800 text-white min-h-screen overflow-hidden">
  <!-- Effet cercle en arrière-plan -->
  <div class="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 w-[700px] h-[700px] rounded-full blur-3xl opacity-70 z-0"
    style="background: radial-gradient(circle, #6DDCFF, #D862B4, #7F60F9);">
  </div>

  <!-- Contenu principal avec un overlay pour bien voir le texte -->
  <div class="relative z-10 container mx-auto py-16 text-center px-4">
    <h2 class="text-3xl md:text-5xl font-bold mb-8">Nos Formules Adaptées à Vos Besoins</h2>

    <!-- Boutons pour changer les tarifs -->
    <div class="flex justify-center mb-16">
      <button
        @click="switchPricing('monthly')"
        :class="{'bg-gray-800': pricingType !== 'monthly', 'bg-[#d862b4]': pricingType === 'monthly'}"
        class="text-white py-2 px-4 rounded-l-full transition-colors duration-300"
      >
        Abonnement mensuel
      </button>
      <button
        @click="switchPricing('oneTime')"
        :class="{'bg-gray-800': pricingType !== 'oneTime', 'bg-[#d862b4]': pricingType === 'oneTime'}"
        class="text-white py-2 px-4 rounded-r-full transition-colors duration-300"
      >
        Paiement unique
      </button>
    </div>

    <!-- Cartes de tarification -->
    <div class="flex flex-wrap justify-center gap-8 md:gap-6 relative z-10">
      <!-- Carte de gauche ( Site Web Basique) -->
      <div class="border border-pink-500 rounded-lg p-8 w-full md:w-80 bg-black bg-opacity-60">
        <h2 class="text-xl font-semibold mb-2"> Site Web Basique</h2>
        <p class="mb-4">Engagement 12 mois</p>
        <p class="text-3xl md:text-4xl font-bold mb-4">
          <span class="text-sm font-normal">à partir de </span> <br>
          {{ basicPrice }}
          <span class="text-lg font-normal">
            {{ pricingType === 'monthly' ? '/ mois' : '/ unique' }}
          </span>
        </p>
        <ul class="text-left mb-8">
          <li class="mb-2"><i class="fas fa-check text-green-400"></i> Design responsive (1 à 5 pages)</li>
          <li class="mb-2"><i class="fas fa-check text-green-400"></i> Optimisation SEO de base</li>
          <li class="mb-2"><i class="fas fa-check text-green-400"></i> Hébergement inclus 1 an</li>
          <li class="mb-2"><i class="fas fa-check text-green-400"></i> Formulaire de contact</li>
        </ul>
        <a href="https://calendly.com/contact-datafuse/consultation-de-projet-avec-datafuse">
  <button class="bg-[#d862b4] text-white py-2 px-4 rounded-full transition duration-300 hover:bg-pink-600">
    Démarrez votre projet
  </button>
</a>
      </div>

      <!-- Carte du milieu ( E-commerce & Web App) -->
      <div class="bg-pink-500 rounded-lg p-8 w-full md:w-80 relative">
        <div class="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white text-[#d862b4] py-1 px-4 rounded-full">
          le plus populaire
        </div>
        <h2 class="text-xl font-semibold mb-2"> E-commerce & Web App</h2>
        <p class="mb-4">Engagement 12 mois</p>
        <p class="text-3xl md:text-4xl font-bold mb-4">
          <span class="text-sm font-normal">à partir de </span> <br>
          {{ ecommercePrice }}
          <span class="text-lg font-normal">
            {{ pricingType === 'monthly' ? '/ mois' : '/ unique' }}
          </span>
        </p>
        <ul class="text-left mb-8">
          <li class="mb-2"><i class="fas fa-check text-green-400"></i> Boutique en ligne complète</li>
          <li class="mb-2"><i class="fas fa-check text-green-400"></i> Intégration paiement sécurisé</li>
          <li class="mb-2"><i class="fas fa-check text-green-400"></i> Gestion des stocks et des commandes</li>
          <li class="mb-2"><i class="fas fa-check text-green-400"></i> Application web sur mesure</li>
        </ul>
        <button class="bg-white text-[#d862b4] py-2 px-4 rounded-full transition duration-300 hover:bg-[#d862b4] hover:text-white">Démarrez votre projet</button>
      </div>

      <!-- Carte de droite ( Automatisation IA) -->
      <div class="border border-pink-500 rounded-lg p-8 w-full md:w-80 bg-black bg-opacity-60">
        <h2 class="text-xl font-semibold mb-2"> Automatisation IA</h2>
        <p class="mb-4">Engagement 12 mois</p>
        <p class="text-3xl md:text-4xl font-bold mb-4">
          <span class="text-sm font-normal mr-1">à partir de </span> <br>
          {{ automationPrice }}
          <span class="text-lg font-normal">
            {{ pricingType === 'monthly' ? '/ mois' : '/ unique' }}
          </span>
        </p>
        <ul class="text-left mb-8">
          <li class="mb-2"><i class="fas fa-check text-green-400"></i> Automatisation des tâches répétitives</li>
          <li class="mb-2"><i class="fas fa-check text-green-400"></i> Développement de chatbot IA</li>
          <li class="mb-2"><i class="fas fa-check text-green-400"></i> Analyse prédictive des données</li>
          <li class="mb-2"><i class="fas fa-check text-green-400"></i> Génération automatique de rapports</li>
        </ul>
        <a href="https://calendly.com/contact-datafuse/consultation-de-projet-avec-datafuse">
  <button class="bg-[#d862b4] text-white py-2 px-4 rounded-full transition duration-300 hover:bg-pink-600">
    Démarrez votre projet
  </button>
</a>      </div>
    </div>
  </div>
</div>


  </template>
  
  <script setup>
  import { ref } from 'vue';
  
  const pricingType = ref('monthly');
  const basicPrice = ref(pricingType.value === 'monthly' ? '50€' : '500€');
  const ecommercePrice = ref(pricingType.value === 'monthly' ? '99€' : '1000€');
  const automationPrice = ref(pricingType.value === 'monthly' ? '150€' : '1500€');
  
  function switchPricing(type) {
    pricingType.value = type;
    basicPrice.value = type === 'monthly' ? '50€' : '500€';
    ecommercePrice.value = type === 'monthly' ? '99€' : '1000€';
    automationPrice.value = type === 'monthly' ? '150€' : '1500€';
  }
  </script>
  
  <style scoped>
  .bg-opacity-60 {
    background-color: rgba(0, 0, 0, 0.6);
  }
  </style>
  
  
  <style scoped>
  html {
    font-family: "Inter", sans-serif;
  }
  button {
    transition: background-color 0.3s;
  }
  
  </style>
  